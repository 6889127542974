
import { defineComponent, onMounted, ref, useContext, useRoute, useRouter, useAsync } from "@nuxtjs/composition-api";
import { baPaymentStore, baStore } from "@/store";
import { provideStore, getStore } from "@/compositions/store";
import "@/assets/css/common.css";

type Menu = {
  type?: string;
  position?: string;
  to?: string;
  pathList?: RegExp[];
  icon?: string;
  activeIcon?: string;
  title?: string;
  isReceive?: boolean;
  receiveDisplay?: boolean;
  onlyOwner?: boolean;
  showCondition?: () => boolean;
  subItems?: Menu[];
};

export default defineComponent({
  middleware: ["auth-general", "toggle-chat-bot"],
  setup() {
    provideStore();

    const { onResize, isMobile, mobileWidth } = getStore();

    const context = useContext();
    const router = useRouter();
    const route = useRoute();

    // 定数の強制読み込み
    // constants.phpの値の追加・変更・削除が合った場合はVERSIONを変更して強制的に読み込ませる
    const constants = context.store.state.constants.constants;
    if (constants && (!constants.VERSION || (constants.VERSION && constants.VERSION !== "1.0.0"))) {
      localStorage.removeItem("constants");
      context.store.dispatch("constants/getListConstants");
    }

    const drawer = ref<boolean>(false);
    const isShownSubMenu = ref<boolean>(false);

    const menuItems: Menu[] = [
      {
        icon: "mdi-home-outline",
        activeIcon: "mdi-home",
        title: "TOP",
        to: "/top",
        pathList: [],
        onlyOwner: false
      },
      { type: "divider" },
      {
        type: "subheader",
        title: "発行機能"
      },
      {
        icon: "mdi-calculator-variant-outline",
        activeIcon: "mdi-calculator-variant",
        title: "見積書",
        to: "/estimates/list",
        pathList: [/^\/estimates(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-truck-outline",
        activeIcon: "mdi-truck",
        title: "納品書",
        to: "/delivery-slips/list",
        pathList: [/^\/delivery-slips(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-receipt-text-outline",
        activeIcon: "mdi-receipt-text",
        title: "請求書",
        to: "/invoices/list",
        pathList: [/^\/invoices(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-receipt-text-check-outline",
        activeIcon: "mdi-receipt-text-check",
        title: "領収書",
        to: "/receipts",
        pathList: [/^\/receipts(\/.*)?$/],
        onlyOwner: false
      },
      { type: "divider" },
      {
        type: "subheader",
        title: "受取機能",
        receiveDisplay: true,
        isReceive: true
      },
      {
        isReceive: true,
        icon: "mdi-receipt-text-outline",
        activeIcon: "mdi-receipt-text",
        title: "請求書",
        to: "/receive/invoices",
        pathList: [/^\/receive\/invoices(\/.*)?$/],
        onlyOwner: false
      },
      {
        isReceive: true,
        icon: "mdi-receipt-text-check-outline",
        activeIcon: "mdi-receipt-text-check",
        title: "領収書",
        to: "/receive/receipts",
        pathList: [/^\/receive\/receipts(\/.*)?$/],
        onlyOwner: false
      },
      {
        isReceive: true,
        icon: "mdi-text-box-multiple-outline",
        activeIcon: "mdi-text-box-multiple",
        title: "その他帳票",
        to: "/receive/others",
        pathList: [/^\/receive\/others(\/.*)?$/],
        onlyOwner: false
      },
      {
        type: "divider",
        isReceive: true
      },
      {
        icon: "mdi-chart-line",
        activeIcon: "mdi-chart-line",
        title: "レポート",
        to: "/reports/list",
        pathList: [/^\/reports(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-cog-outline",
        title: "設定",
        to: "#",
        subItems: [
          {
            icon: "mdi-shield-account-outline",
            activeIcon: "mdi-shield-account",
            title: "アカウント",
            to: "/accounts/settings",
            onlyOwner: true,
            pathList: [
              /^\/terms(\/.*)?$/,
              /^\/accounts\/settings(\/.*)?$/,
              /^\/accounts\/register\/addplan(\/.*)?$/,
              /^\/accounts\/register\/changeplan(\/.*)?$/,
              /^\/accounts\/change(\/.*)?$/
            ]
          },
          {
            icon: "mdi-account-cog-outline",
            activeIcon: "mdi-account-cog",
            title: "ユーザー管理",
            to: "/accounts/user/list",
            pathList: [/^\/accounts\/user(\/.*)?$/],
            onlyOwner: true
          },
          {
            icon: "mdi-shield-alert-outline",
            activeIcon: "mdi-shield-alert",
            title: "自社情報",
            to: "/companies/list",
            pathList: [/^\/companies(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-office-building-outline",
            activeIcon: "mdi-office-building",
            title: "取引先情報",
            to: "/customers/list",
            pathList: [/^\/customers(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "品目情報",
            to: "/items/list",
            pathList: [/^\/items(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "帳票設定",
            to: "/settings/documents",
            pathList: [/^\/settings(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "事務処理規程",
            to: "/office-process-rule-templates",
            pathList: [/^\/office-process-rule-templates(\/.*)?$/],
            onlyOwner: false
          }
        ]
      },
      // 下部メニュー
      {
        icon: "mdi-help-circle-outline",
        activeIcon: "mdi-help-circle",
        title: "サポート",
        to: "/support/faq",
        pathList: [/^\/support(\/.*)?$/],
        position: "bottom"
      }
      // クッキーポリシーメニューの表示非表示(暫定)
      // {
      //   icon: "mdi-key-outline",
      //   activeIcon: "mdi-key",
      //   title: "クッキーポリシー",
      //   to: "/cookie-policy",
      //   position: "bottom"
      // }
    ];

    // クッキーポリシーメニューの表示非表示(暫定)
    const isViewcookiePolicy = ref<boolean>(false);
    useAsync(async () => {
      await context.$axios.get("/api/v1/master/service/terms/cookie").then((response) => {
        if (response.data.data[0]) {
          isViewcookiePolicy.value = true;
        }
      });
    });

    // 左ナビが現在のページか判断
    const isActive = (menu: Menu | string, isSubmenu = false): boolean => {
      const currentPath = route.value.path;

      // クッキーポリシーメニューの表示非表示(暫定)
      if (typeof menu === "string") {
        return currentPath === menu;
      }

      // 指定した子ページのパスと合致するかチェック
      if (menu.pathList.length) {
        for (let index = 0; index < menu.pathList.length; index++) {
          if (menu.pathList[index].test(currentPath)) {
            if (isSubmenu) {
              isShownSubMenu.value = true;
            }
            return true;
          }
        }
      }

      // 遷移先と合致するかチェック
      return currentPath === menu.to;
    };

    // 左メニューの各メニューを表示非表示するか取得
    const isMenuDisabled = (): boolean => {
      if (baPaymentStore.user.company_unique_code) {
        // 登録済み
        return false;
      }

      // 未登録
      return true;
    };

    // エラーページかどうかの判定
    const isErrorPage = (): boolean => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return context._errored;
    };

    // 現在のパスと異なる場合のみ画面遷移
    const clickHandler = (event: any, path: string): void => {
      event.preventDefault();
      event.stopPropagation();
      if (route.value.path !== path) {
        router.push(path);
      }
    };

    onMounted(() => {
      onResize();

      // pcの場合は左メニューを最初から表示する
      if (!isMobile.value) {
        drawer.value = true;
      }

      if (isErrorPage()) {
        isMobile.value = true;
      }
    });

    return {
      baStore,
      drawer,
      menuItems,
      isShownSubMenu,
      onResize,
      mobileWidth,
      isMobile,
      isMenuDisabled,
      isErrorPage,
      clickHandler,
      baPaymentStore,
      isViewcookiePolicy, // クッキーポリシーメニューの表示非表示(暫定)
      isActive
    };
  },
  head() {
    return {
      title: ""
    };
  }
});
