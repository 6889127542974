import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AsyncData } from "@/api/types";
import { baStore } from "@/store";

class ApiClient {
  // -------------------------------------------------------
  // Methods
  // -------------------------------------------------------
  /**
   * Get Request
   */
  get<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const query = data || null;

    return this.request(url, "GET", responseType, null, query) as any;
  }

  /**
   * Post Request
   */
  post<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "POST", responseType, body, null) as any;
  }

  /**
   * Patch Request
   */
  patch<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "PATCH", responseType, body, null) as any;
  }

  /**
   * Put Request
   */
  put<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "PUT", responseType, body, null) as any;
  }

  /**
   * Delete Request
   */
  delete<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "DELETE", responseType, body, null) as any;
  }

  // -------------------------------------------------------
  // Private Methods
  // -------------------------------------------------------
  /**
   * HTTPリクエスト
   */
  private async request(
    url: string,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    responseType: "json" | "blob",
    body: Record<string, string> | null,
    query: Record<string, string> | null
  ) {
    try {
      const apiUrl = process.env.API_URL;
      const token = localStorage.getItem("auth._token.bamidp") || "";

      // ロギング用の共通データをヘッダーにセット
      // TODO: storeリファクタ時に修正
      // const screenName =
      //   document.title.split("｜")[0].trimEnd() || (context.route && context.route.value.fullPath)
      //     ? context.route.value.fullPath
      //     : "-";

      return await axios({
        method,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: token,
          "X-Amzn-Trace-Id": "Root=" + uuidv4(),
          "X-Ba-Bank-Name": encodeURI(baStore.user.ba_user_bank_name)
          // "X-Ba-Screen-Name": encodeURI(screenName)
        },
        data: body,
        params: query,
        responseType
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default ApiClient;
