// チャットボットの表示非表示の切り替え
export const toggleChatBot = (path: string) => {
  const visiblePaths = [
    "/top",
    "/estimates/list",
    "/delivery-slips/list",
    "/invoices/list",
    "/reports/list",
    "/receipts",
    "/accounts/settings",
    "/accounts/user/list",
    "/companies/list",
    "/customers/list",
    "/items/list",
    "/support/faq",
    "/accounts/register",
    "/accounts/register/confirm"
  ];

  const chatPlusView = document.getElementById("chatplusview");
  if (visiblePaths.includes(path)) {
    // すでにscriptが読み込まれていたらDOMを直接操作し、読み込まれていない場合はscriptの読み込み処理を行う
    chatPlusView ? (chatPlusView.style.display = "block") : createChatBot();
    return;
  }

  if (chatPlusView && !visiblePaths.includes(path)) {
    chatPlusView.style.display = "none";
  }
};

interface chatBotDocument extends Document {
  __cp_d: string;
  __cp_c: string;
}

const createChatBot = () => {
  const d = document as chatBotDocument;
  const s = "https://app.chatplus.jp/cp.js";
  d.__cp_d = "https://app.chatplus.jp";
  d.__cp_c = "73fc00a5_1";
  const a = d.createElement("script");
  const m = d.getElementsByTagName("script")[0];
  a.async = true;
  a.src = s;
  m.parentNode && m.parentNode.insertBefore(a, m);
};
