/* eslint-disable */
import { baStore, baPaymentStore } from "@/store";
import { isUseValid, isPaymentEndDateValid } from "@/utils";
import { getBaCompany } from "@/apis/user";
import { toggleChatBot } from "~/utils/chatbot";

export default async function ({ route, $auth, redirect, error, $sentry }) {
  /**
   * エラー発生時、Sentryにイベントを時の共通データをセットする
   *
   * 仮にロギング処理内でエラーが発生しても、無視して通常処理を続行させるようにしています。
   */
  const setDimensionToSentry = (baStore) => {
    try {
      $sentry.setUser({
        ba_company_name: baStore.user.ba_company_name,
        ba_company_type: baStore.user.ba_company_type,
        ba_company_unique_code: baStore.user.ba_company_unique_code,
        ba_user_type: baStore.user.ba_user_type,
        ba_user_unique_code: baStore.user.ba_user_unique_code,
        user_kind: "general"
      });
    } catch {}
  };

  if (!$auth.loggedIn) {
    // bamidpにログインしていない場合はエラー
    error({ statusCode: 404 });
    console.error("未ログイン");
  } else {
    // アクセストークンでユーザ情報（銀行名など）取得
    await baStore.loadUser();

    // エラー発生時にSentryへ常に送信する基本情報をセット
    setDimensionToSentry(baStore);

    if (!baStore.user.is_credit_card) {
      // BA側でクレジットカード登録されていない場合
      error({ statusCode: 404 });
      console.error("BA側でクレカ登録されていない");
      return;
    } else if (
      baStore.user.ba_user_type !== "company_owner" &&
      baStore.user.ba_user_type !== "company_user" &&
      baStore.user.ba_user_type !== "system_admin" &&
      baStore.user.ba_user_type !== "bank_admin" &&
      baStore.user.ba_user_type !== "branch_admin" &&
      baStore.user.ba_user_type !== "branch_user"
    ) {
      // BAのオーナー、ビジネスユーザー、システム管理者、金融機関（事務局）、金融機関（支店）以外ならエラー
      error({ statusCode: 404 });
      console.error("ロールエラー");
      return;
    } else {
      // BAのオーナー、ビジネスユーザー、システム管理者、金融機関（事務局）、金融機関（支店）なら
      // BA Paymentユーザー取得
      await baPaymentStore.loadUser();

      // 権限変更ページ以外は全ページでチェックを行う
      if (route.path !== "/accounts/change/permissions") {
        let isPayment = true;
        if (baPaymentStore.paymentInfo.plan_id === 0) {
          // まだba-paymentを使っていない状態ならば無課金状態
          isPayment = false;
        } else if (baPaymentStore.paymentInfo.withdrawal_date) {
          // 退会日付が設定されていた場合は、課金終了日が今日以下であれば無課金状態とみなす。
          isPayment = isPaymentEndDateValid(baPaymentStore.paymentInfo.end_date);
        }

        if (isPayment) {
          // 課金状態ならば
          // baのuser_typeをba-payment用に変換
          // todo 定数化お願いします
          const BA_USER_TYPE_OWNER = 1;
          const BA_USER_TYPE_USER = 2;
          const ba_user_role = baStore.user.ba_user_type === "company_owner" ? BA_USER_TYPE_OWNER : BA_USER_TYPE_USER;

          // BAのオーナーのデータ取得
          const result = await getBaCompany(baStore.user.ba_company_unique_code);
          const baOwner = result.data.result.owner;

          if (baPaymentStore.user.role === 0) {
            // ba-paymentのユーザーデータがない（ビジネスユーザーのはず）
            if (baStore.user.ba_user_unique_code === baOwner.user_unique_code) {
              // BAのユーザーとBAのオーナーのuser_unique_codeに差異がない場合は変更へ
              redirect("/accounts/change/permissions");
              return;
            }
          } else {
            // ba-paymentのユーザーデータがある
            if (ba_user_role !== baPaymentStore.user.role) {
              // BAとba-paymentでユーザーのロールに差異がある場合は変更へ
              redirect("/accounts/change/permissions");
              return;
            }
          }
        }
      }

      if (route.path === "/top") {
        if (baPaymentStore.user.company_unique_code) {
          // ユーザーのデータが取得できた場合
          if (isUseValid(baPaymentStore.user.use_start_date, baPaymentStore.user.use_end_date)) {
            // 利用期限内であれば何もしない
          } else if (baStore.user.ba_user_type === "company_owner") {
            // 利用期限外かつオーナーの場合は新規登録へ
            redirect("/accounts/register");
          } else if (baStore.user.ba_user_type === "company_user") {
            // 利用期限外かつビジネスユーザーの場合はエラー
            error({
              statusCode: 400,
              message: "利用登録されていないビジネスユーザのため、ご利用いただけません。"
            });
            console.error("新規登録不可");
          }
        } else if (!baPaymentStore.user.company_unique_code) {
          // 未登録の場合
          if (baStore.user.ba_user_type === "company_owner") {
            // オーナーの場合は新規登録へ
            redirect("/accounts/register");
          } else if (baStore.user.ba_user_type === "company_user") {
            // ビジネスユーザーの場合はエラー
            error({
              statusCode: 400,
              message: "利用登録されていないビジネスユーザのため、ご利用いただけません。"
            });
            console.error("新規登録不可");
          } else if (
            baStore.user.ba_user_type === "system_admin" ||
            baStore.user.ba_user_type === "bank_admin" ||
            baStore.user.ba_user_type === "branch_admin" ||
            baStore.user.ba_user_type === "branch_user"
          ) {
            // システム管理者、金融機関（事務局）、金融機関（支店）の場合は管理画面TOPにリダイレクト
            redirect("/admin/companies/list");
          }
        }
      } else if (
        route.path !== "/accounts/register" &&
        route.path !== "/accounts/register/confirm" &&
        route.path !== "/accounts/change/permissions" &&
        route.path !== "/terms/chanto-term" &&
        route.path !== "/terms/kokopelli-id-term" &&
        route.path !== "/support/faq" &&
        route.path !== "/support/qa" &&
        route.path !== "/cookie-policy"
      ) {
        // ユーザー登録画面以外チェック
        if (baPaymentStore.user.use_start_date && baPaymentStore.user.use_end_date) {
          // storeにBAPaymentユーザーデータが保存されている場合
          if (!isUseValid(baPaymentStore.user.use_start_date, baPaymentStore.user.use_end_date)) {
            // 利用期限外であればエラーにする
            error({ statusCode: 500, message: "利用期限が切れています。" });
            console.error("利用期限切れ");
            return;
          }
        } else {
          // 何も取得できなかった場合 (未登録)エラーを表示する
          error({ statusCode: 404 });
          console.error("未登録");
          return;
        }
      }
    }
  }
}
