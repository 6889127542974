import { GetterTree, ActionTree, MutationTree } from "vuex";
import { PaymentConstantsApi } from "@/api/payment/payment-constants-api";

export const state = (): any => ({
  constants: {} as object
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  constants: (state) => state.constants
};

export const mutations: MutationTree<RootState> = {
  SET_CONSTANTS: (state, data: object) => {
    state.constants = data;
    localStorage.setItem("constants", JSON.stringify(data));
  }
};

export const actions: ActionTree<RootState, RootState> = {
  async getListConstants({ commit }) {
    const constants = localStorage.getItem("constants");

    if (constants) {
      commit("SET_CONSTANTS", JSON.parse(constants));
      return;
    }
    try {
      const data = await getConstatns();

      commit("SET_CONSTANTS", data);
    } catch (err) {}
  }
};

/** Api */
const paymentConstantsApi = new PaymentConstantsApi();

const getConstatns = () => {
  return paymentConstantsApi.getConstants().then((data) => {
    return data.data;
  });
};
